import styled from 'styled-components';

const Icon = styled.img`
    width: ${({ width }) => width || '20px'};
    height: ${({ height }) => height || '18px'};
    margin-right: 6px;
    margin-left: ${({ marginLeft }) => marginLeft || '0px'};
`;

export default Icon;
