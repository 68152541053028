import convertUTCDateToLocalDate from "../helpers/getLocalTimeFromUTC";
const calculateStatus = (transferRequestedTime, transferFinishedTime, error) => {
    let status;
    const now = new Date().getTime();
    const transferFinishedTimeHours = convertUTCDateToLocalDate(new Date(transferFinishedTime)).getTime();
    const difference = now - transferFinishedTimeHours; // This will give difference in milliseconds
    const resultInMinutes = Math.round(difference / 60000);
    if(transferRequestedTime !== null && transferFinishedTime === null) {
        status = 'inProgress';
    } else if (transferRequestedTime !== null && transferFinishedTime !== null) {
        status = 'inProgress';
    } else if (transferRequestedTime === null && transferFinishedTime !== null && (resultInMinutes > 60)) {
        status = '';
    } else if (transferRequestedTime === null && transferFinishedTime !== null) {
        status = 'done';
    } else if (transferRequestedTime === null && transferFinishedTime === null) {
        status = '';
    } else if (error) {
        status = 'error';
    }
    return status;
}

export default calculateStatus;
